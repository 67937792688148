import React from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import { useState } from "react";

const PaypalSuccess = props => {
  const [processed] = useState(false);
  return (
    <Layout processed={processed}>
      <main className="error-page">
        <div className="error-container">
          <>
            <h1>Payment successful</h1>
            <p>Thank you for your order. Your resources will be in your inbox shortly.</p>
            <Link to="/" className="btn">
              Homepage
            </Link>
          </>
        </div>
      </main>
    </Layout>
  );
};

export default PaypalSuccess;
